import revive_payload_client_XHsOGocF9S from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_oYpR9p4yZs from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Y9UTenOMYY from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_AzNl2mhecZ from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.1.0_@types+node@22.13.1_jiti@2.4.2_sass@1.84.0__qabswzic6rawci3djlkftmtbju/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_4N4F3KWtTE from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6uaJR88J0E from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZIrnTB6xd3 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cjvVPENw8g from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-frontend/web-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_bCgejJaCtr from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_hjCrT3Mgqd from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_xrY8REeVYf from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_RYhFRgzRQ8 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_EUdaQWZX6B from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.34.6_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import apollo_craft_sclci9JHWi from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-craft.ts";
import apollo_error_handler_a0IiUcX0mN from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-error-handler.ts";
import crypto_IPXw5s4rs8 from "/home/runner/work/web-frontend/web-frontend/plugins/crypto.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/web-frontend/web-frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_XHsOGocF9S,
  unhead_oYpR9p4yZs,
  router_Y9UTenOMYY,
  _0_siteConfig_AzNl2mhecZ,
  payload_client_4N4F3KWtTE,
  navigation_repaint_client_6uaJR88J0E,
  check_outdated_build_client_ZIrnTB6xd3,
  chunk_reload_client_cjvVPENw8g,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bCgejJaCtr,
  switch_locale_path_ssr_hjCrT3Mgqd,
  i18n_xrY8REeVYf,
  plugin_RYhFRgzRQ8,
  plugin_EUdaQWZX6B,
  apollo_craft_sclci9JHWi,
  apollo_error_handler_a0IiUcX0mN,
  crypto_IPXw5s4rs8,
  sentry_client_shVUlIjFLk
]