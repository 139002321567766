
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46uri_93LCFzvqCSUsMeta } from "/home/runner/work/web-frontend/web-frontend/pages/[...uri].vue?macro=true";
import { default as _91block_93M3kdtJOtGHMeta } from "/home/runner/work/web-frontend/web-frontend/pages/block-tests/[block].vue?macro=true";
import { default as configdqYmhWq4vZMeta } from "/home/runner/work/web-frontend/web-frontend/pages/block-tests/blocks/ds_ButtonGroupBlock/config.js?macro=true";
import { default as configUTr2eddUQ7Meta } from "/home/runner/work/web-frontend/web-frontend/pages/block-tests/blocks/ds_GalleryBlock/config.js?macro=true";
import { default as configC6FM1Mxr7RMeta } from "/home/runner/work/web-frontend/web-frontend/pages/block-tests/blocks/ds_ImagesBlock/config.js?macro=true";
import { default as configMp3pZUvtJxMeta } from "/home/runner/work/web-frontend/web-frontend/pages/block-tests/blocks/ds_TabsBlock/config.js?macro=true";
import { default as indexJW3pWy26CiMeta } from "/home/runner/work/web-frontend/web-frontend/pages/block-tests/index.vue?macro=true";
import { default as block_45testsr6U322rMMnMeta } from "/home/runner/work/web-frontend/web-frontend/pages/block-tests.vue?macro=true";
import { default as indexBAXdbXdxHCMeta } from "/home/runner/work/web-frontend/web-frontend/pages/index.vue?macro=true";
import { default as searchibSVO7DIpzMeta } from "/home/runner/work/web-frontend/web-frontend/pages/search.vue?macro=true";
import { default as accordionzV2JyiOY2hMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/accordion.vue?macro=true";
import { default as athletics_45calendarYpUxwD0E6JMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/athletics-calendar.vue?macro=true";
import { default as button_45groupkzCsADRrjUMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/button-group.vue?macro=true";
import { default as dividerowdPtMEVZ5Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/divider.vue?macro=true";
import { default as events_45calendarxWsTlPLOU4Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/events-calendar.vue?macro=true";
import { default as events_45listingTSKtEgN7WLMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/events-listing.vue?macro=true";
import { default as feature_45tilesJ5NQXRCYYNMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/feature-tiles.vue?macro=true";
import { default as formBLoowzZA1yMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/form.vue?macro=true";
import { default as galleryuoXaZAKWiUMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/gallery.vue?macro=true";
import { default as image_45and_45links_45columnsS0kCIRIPLoMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/image-and-links-columns.vue?macro=true";
import { default as image_45and_45text_45accordionStXRGuLKtrMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/image-and-text-accordion.vue?macro=true";
import { default as imagesp6VHgCiMN3Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/images.vue?macro=true";
import { default as indexGeMX4Cv8YdMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/index.vue?macro=true";
import { default as layoutwMP5u2SY6KMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/layout.vue?macro=true";
import { default as linksq2QVmeX9p8Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/links.vue?macro=true";
import { default as news_45listing4OfUInRJCqMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/news-listing.vue?macro=true";
import { default as quoteDGFkZ4IqvuMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/quote.vue?macro=true";
import { default as rich_45textQohbcuPELlMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/rich-text.vue?macro=true";
import { default as social_45media_45feedQbauYEtMJwMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/social-media-feed.vue?macro=true";
import { default as staff_45directory2g1i5qBD1bMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/staff-directory.vue?macro=true";
import { default as tableIxpsONaWshMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/table.vue?macro=true";
import { default as tabs7rVXRqYA05Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/tabs.vue?macro=true";
import { default as tilesKxKTzmFLxeMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/tiles.vue?macro=true";
import { default as videomoHypu999mMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/video.vue?macro=true";
import { default as sectionsbZPZxuIu9qMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections.vue?macro=true";
import { default as component_45stubKKiATWovdXMeta } from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKKiATWovdX } from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_m_iun5katz4s6adymgdfsfj7ra5y/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "uri___en",
    path: "/:uri(.*)*",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/[...uri].vue")
  },
  {
    name: block_45testsr6U322rMMnMeta?.name,
    path: "/block-tests",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/block-tests.vue"),
    children: [
  {
    name: "block-tests-block___en",
    path: ":block()",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/block-tests/[block].vue")
  },
  {
    name: "block-tests-blocks-ds_ButtonGroupBlock-config___en",
    path: "blocks/ds_ButtonGroupBlock/config",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/block-tests/blocks/ds_ButtonGroupBlock/config.js")
  },
  {
    name: "block-tests-blocks-ds_GalleryBlock-config___en",
    path: "blocks/ds_GalleryBlock/config",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/block-tests/blocks/ds_GalleryBlock/config.js")
  },
  {
    name: "block-tests-blocks-ds_ImagesBlock-config___en",
    path: "blocks/ds_ImagesBlock/config",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/block-tests/blocks/ds_ImagesBlock/config.js")
  },
  {
    name: "block-tests-blocks-ds_TabsBlock-config___en",
    path: "blocks/ds_TabsBlock/config",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/block-tests/blocks/ds_TabsBlock/config.js")
  },
  {
    name: "block-tests___en",
    path: "",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/block-tests/index.vue")
  }
]
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/index.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/search.vue")
  },
  {
    name: sectionsbZPZxuIu9qMeta?.name,
    path: "/sections",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections.vue"),
    children: [
  {
    name: "sections-accordion___en",
    path: "accordion",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/accordion.vue")
  },
  {
    name: "sections-athletics-calendar___en",
    path: "athletics-calendar",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/athletics-calendar.vue")
  },
  {
    name: "sections-button-group___en",
    path: "button-group",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/button-group.vue")
  },
  {
    name: "sections-divider___en",
    path: "divider",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/divider.vue")
  },
  {
    name: "sections-events-calendar___en",
    path: "events-calendar",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/events-calendar.vue")
  },
  {
    name: "sections-events-listing___en",
    path: "events-listing",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/events-listing.vue")
  },
  {
    name: "sections-feature-tiles___en",
    path: "feature-tiles",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/feature-tiles.vue")
  },
  {
    name: "sections-form___en",
    path: "form",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/form.vue")
  },
  {
    name: "sections-gallery___en",
    path: "gallery",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/gallery.vue")
  },
  {
    name: "sections-image-and-links-columns___en",
    path: "image-and-links-columns",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/image-and-links-columns.vue")
  },
  {
    name: "sections-image-and-text-accordion___en",
    path: "image-and-text-accordion",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/image-and-text-accordion.vue")
  },
  {
    name: "sections-images___en",
    path: "images",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/images.vue")
  },
  {
    name: "sections___en",
    path: "",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/index.vue")
  },
  {
    name: "sections-layout___en",
    path: "layout",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/layout.vue")
  },
  {
    name: "sections-links___en",
    path: "links",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/links.vue")
  },
  {
    name: "sections-news-listing___en",
    path: "news-listing",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/news-listing.vue")
  },
  {
    name: "sections-quote___en",
    path: "quote",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/quote.vue")
  },
  {
    name: "sections-rich-text___en",
    path: "rich-text",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/rich-text.vue")
  },
  {
    name: "sections-social-media-feed___en",
    path: "social-media-feed",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/social-media-feed.vue")
  },
  {
    name: "sections-staff-directory___en",
    path: "staff-directory",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/staff-directory.vue")
  },
  {
    name: "sections-table___en",
    path: "table",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/table.vue")
  },
  {
    name: "sections-tabs___en",
    path: "tabs",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/tabs.vue")
  },
  {
    name: "sections-tiles___en",
    path: "tiles",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/tiles.vue")
  },
  {
    name: "sections-video___en",
    path: "video",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/video.vue")
  }
]
  },
  {
    name: component_45stubKKiATWovdXMeta?.name,
    path: "/sitemaps-1-sitemap.xml",
    component: component_45stubKKiATWovdX
  },
  {
    name: component_45stubKKiATWovdXMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubKKiATWovdX
  }
]