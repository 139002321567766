export const ds_Homepage_Entry = `
    fragment ds_Homepage_Entry on ds_Homepage_Entry {
        canonicalUid
        typeHandle
        popup {
            canonicalUid
        }
        preFooter {
            canonicalUid
        }
        contentBuilder {
            __typename
        }
    }
`
