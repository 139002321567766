export const siteQuery = gql`
    query site {
        digistormComponents
        siteDetails
        colorThemes {
            id
            title
            defaultTheme
            themeData
        }
        entries(section: "ds_Pages", level: 1, pageVisibility: ["visible", "hideFromSearch"]) {
            id
            uri
            title
            ...on ds_RedirectPage_Entry {
                redirectLink {
                    target
                }
            }
            children(section: "ds_Pages", pageVisibility: ["visible", "hideFromSearch"]) {
                id
                uri
                title
                ...on ds_RedirectPage_Entry {
                    redirectLink {
                        target
                    }
                }
                children(section: "ds_Pages", pageVisibility: ["visible", "hideFromSearch"]) {
                    id
                    uri
                    title
                    ...on ds_RedirectPage_Entry {
                        redirectLink {
                            target
                        }
                    }
                }
            }
        }

        globalSets {
            id
            name

            ... on ds_Logos_GlobalSet {
                id
                name
                logos_StickyNavLogoSize
                logos_StickyNavLogo {
                    url
                }
                logos_HeaderLogoSize
                logos_HeaderLogo {
                    url
                }
                logos_FooterLogoSize
                logos_FooterLogo {
                    url
                }
                logos_BrowserIcon {
                    url
                }
            }
            ... on ds_Navigation_GlobalSet {
                mainNav_LogoPosition
                mainNav_PageLinksPosition
                mainNav_ColorTheme
                mainNav_ColorPalette
                mainNav_ShowBackground
                mainNav_Quicklinks {
                    ... on ds_MainNavQuicklinksItem_Entry {
                        mainNavQuicklinksItem_Heading
                        mainNavQuicklinksItem_Links {
                            url
                            text
                            target
                            ariaLabel
                            ... on mainNavQuicklinksItem_Links_Custom_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                            ... on mainNavQuicklinksItem_Links_Entry_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                            ... on mainNavQuicklinksItem_Links_Url_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                            ... on mainNavQuicklinksItem_Links_Phone_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                            ... on mainNavQuicklinksItem_Links_Email_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                        }
                    }
                }
                mainNav_Buttons {
                    text
                    url
                    target
                    ariaLabel
                }
                mainNav_Watermark {
                    id
                    url
                }
                footer_Alignment
                footer_ColorPalette
                footer_BodyText
                footer_ColorTheme
                footer_QuicklinksHeading
                footer_DisclaimerText
                footer_Quicklinks {
                    url
                    text
                    target
                }
                footer_PolicyLinks {
                    url
                    linkText
                    target
                }
                footer_ContactDetails {
                    ... on ds_FooterContactDetailsItem_Entry {
                        footerContactDetailsItem_Heading
                        footerContactDetailsItem_Links {
                            url
                            text
                            target
                            ... on footerContactDetailsItem_Links_Custom_LinkType {
                                icon {
                                    type
                                    name
                                    set
                                }
                            }
                            ... on footerContactDetailsItem_Links_Entry_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                            ... on footerContactDetailsItem_Links_Url_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                            ... on footerContactDetailsItem_Links_Email_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                            ... on footerContactDetailsItem_Links_Phone_LinkType {
                                icon {
                                    name
                                    set
                                    type
                                }
                            }
                        }
                    }
                }
            }
            ... on ds_Socials_GlobalSet {
                id
                name
                socials_SocialLinks {
                    ... on socials_SocialLinks_Url_LinkType {
                        title
                        text
                        target
                        ariaLabel
                        url
                        icon {
                            type
                            set
                            name
                        }
                    }
                }
            }

            ... on ds_AlertBar_GlobalSet {
                id
                name
                alertBar_ColorTheme
                alertBar_ColorPalette
                alertBar_Alignment
                alertBar_Items {
                    ... on ds_AlertBarItem_Entry {
                        canonicalUid
                        id
                        alertBarItem_Text
                        alertBarItem_Link {
                            url
                            target
                            text
                        }
                        alertBarItem_Icon {
                            type
                            set
                            name
                        }
                    }
                }
            }
        }
    }
`