export const ds_MicrositeHomepage_Partial = `
    fragment ds_MicrositeHomepage_Partial on ds_MicrositeHomepage_Entry {
        id
        uri
        title

        children {
            id
            uri
            title
            ...on ds_RedirectPage_Entry {
                redirectLink {
                    target
                }
            }
            children {
                id
                uri
                title
                ...on ds_RedirectPage_Entry {
                    redirectLink {
                        target
                    }
                }
            }
        }

        colorTheme
        logos_StickyNavLogoSize
        logos_StickyNavLogo {
            url
        }
        logos_HeaderLogoSize
        logos_HeaderLogo {
            url
        }
        logos_FooterLogoSize
        logos_FooterLogo {
            url
        }
        socials_SocialLinks {
            ... on socials_SocialLinks_Url_LinkType {
                title
                text
                target
                ariaLabel
                url
                icon {
                    type
                    set
                    name
                }
            }
        }
        mainNav_LogoPosition
        mainNav_PageLinksPosition
        mainNav_ColorTheme
        mainNav_ColorPalette
        mainNav_ShowBackground
        mainNav_Quicklinks {
            ... on ds_MainNavQuicklinksItem_Entry {
                mainNavQuicklinksItem_Heading
                mainNavQuicklinksItem_Links {
                    url
                    text
                    target
                    ariaLabel
                    ... on mainNavQuicklinksItem_Links_Custom_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                    ... on mainNavQuicklinksItem_Links_Entry_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                    ... on mainNavQuicklinksItem_Links_Url_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                    ... on mainNavQuicklinksItem_Links_Phone_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                    ... on mainNavQuicklinksItem_Links_Email_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                }
            }
        }
        mainNav_Buttons {
            text
            url
            target
            ariaLabel
        }
        mainNav_Watermark {
            id
            url
        }
        footer_Alignment
        footer_ColorPalette
        footer_BodyText
        footer_ColorTheme
        footer_QuicklinksHeading
        footer_DisclaimerText
        footer_Quicklinks {
            url
            text
            target
        }
        footer_PolicyLinks {
            url
            linkText
            target
        }
        footer_ContactDetails {
            ... on ds_FooterContactDetailsItem_Entry {
                footerContactDetailsItem_Heading
                footerContactDetailsItem_Links {
                    url
                    text
                    target
                    ... on footerContactDetailsItem_Links_Custom_LinkType {
                        icon {
                            type
                            name
                            set
                        }
                    }
                    ... on footerContactDetailsItem_Links_Entry_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                    ... on footerContactDetailsItem_Links_Url_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                    ... on footerContactDetailsItem_Links_Email_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                    ... on footerContactDetailsItem_Links_Phone_LinkType {
                        icon {
                            name
                            set
                            type
                        }
                    }
                }
            }
        }
    }
`